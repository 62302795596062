import Link from "next/link";
import Router, { NextResponse, NextRequest, useRouter } from "next/router";
import Cookies from "js-cookie";
import Logo from "./logo";
import { Nav, Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import langTrans from "./i18n";
import { ImMenu } from "react-icons/im";
import { IoClose } from "react-icons/io5";
const Header = (props) => {
  const router = useRouter();
  const locale = Cookies.get("NEXT_LOCALE")
    ? Cookies.get("NEXT_LOCALE")
    : router.locale;
  const lang = langTrans[locale].menu;
  const [myToggle, setMyToggle] = useState(false);
  const [language, setCurrentLanguage] = useState(Cookies.get("NEXT_LOCALE"));

  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const name = Cookies.get("name");

  let user = token ? true : false;

  const toggleNav = () => {
    setMyToggle(!myToggle);
  };

  useEffect(() => {
    //componentDidMount
    const l = Cookies.get("NEXT_LOCALE");
    setCurrentLanguage(l);
    if (l !== router.locale && !user)
      router.push(router.asPath, router.asPath, { locale: l });

    router.events.on("routeChangeStart", () => {
      setMyToggle(false);
    });
    router.events.on("routeChangeComplete", (url) => {
      setMyToggle(false);
    });
  }, [Cookies.get("NEXT_LOCALE")]);

  const setLanguage = () => {
    if (language == "en") {
      Cookies.set("NEXT_LOCALE", "fr");
      setTimeout(() => {
        router.reload();
      }, 1000);
    } else {
      Cookies.set("NEXT_LOCALE", "en");
      setTimeout(() => {
        router.reload();
      }, 1000);
    }
  };
  //////reload url on new report/////
  const active_report = () => {
    setTimeout(() => {
      demo_user();
    }, 100);
  };
  const demo_user = () => {
    if (Router.asPath == "/credit-reports/report") {
      Router.reload();
    }
  };
  //////////////
  const restoreAdmin = () => {
    Cookies.set("token", Cookies.get("admin_token"));
    Cookies.set("role", Cookies.get("admin_role"));
    Cookies.set("userid", Cookies.get("admin_userid"));
    Cookies.set("name", Cookies.get("admin_name"));
    Cookies.set("email", Cookies.get("admin_email"));
    Cookies.set("viewed_company_id", Cookies.get("exit_simulation"));
    Cookies.set("userData", Cookies.get("admin_userData"));

    Cookies.remove("admin_token");
    Cookies.remove("admin_role");
    Cookies.remove("admin_userid");
    Cookies.remove("admin_name");
    Cookies.remove("admin_email");
    Cookies.remove("admin_companyID");
    Cookies.remove("admin_userData");

    Router.push(`/companies/users/${Cookies.get("exit_simulation")}`);
  };

  if (
    router.pathname.includes("/files") ||
    router.pathname.includes("/external/flinks")
  ) {
    return <></>;
  }

  if (
    router.asPath == "/" ||
    router.asPath == "/forgot-password" ||
    router.pathname == "/reset-password" ||
    router.asPath == "/change-password" ||
    router.pathname == "/external/external-bank" ||
    router.pathname == "/external/plaid" ||
    router.pathname == "/external/external-supplier" ||
    router.pathname == "/external/completed" ||
    router.pathname == "/external/thankyou" ||
    router.asPath == "/reset-password-successful" ||
    router.pathname == "/external/user-onboarding" ||
    router.pathname == "/external/confirmation" ||
    router.pathname == "/external/user-edit"
  ) {
    user = true;
    return <></>;
  }

  if (
    router.pathname == "/404" ||
    router.pathname == "_error" ||
    router.pathname == "/reset-password-successful"
  ) {
    user = false;
    return <></>;
  }

  const onMigration = () => {
    const token = Cookies.get("token");
    const language = Cookies.get("NEXT_LOCALE");
    const userId = Cookies.get("userid");

    window.open(
      `https://dev-v2.portail.alliancecredit.ca/creditrisk-management/${language}/${token}/${userId}`
    );
  };

  if (user && token && name) {
    return (
      <>
        <div className='header'>
          <div className='header_wrap'>
            <div className='container'>
              {Cookies.get("admin_token") ? (
                <div
                  style={{
                    position: "fixed",
                    marginLeft: "40%",
                    paddingTop: "15px",
                  }}
                >
                  {lang.simulating} {name} &nbsp;
                  <button className='lang_btn' onClick={() => restoreAdmin()}>
                    {lang.exit_simulation}
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className='row'>
                <div className='col-6'>
                  <div className='logo_wrap'>
                    <Logo />
                  </div>
                </div>
                <div className='col-6'>
                  <div className='top_headernav'>
                    <div className='user_nav pull-right'>
                      <button className='lang_btn' onClick={setLanguage}>
                        {lang.language}
                      </button>
                      <div className='dropdown pull-right'>
                        <a
                          className='nav-link dropdown-toggle'
                          href='#'
                          id='dropdownMenuButton1'
                          role='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='hello'>
                            {lang.hello} {name}
                          </span>
                          <img
                            src='/icons/down-arrow.png'
                            alt='down arrow'
                            width='12'
                            height='6'
                          />
                        </a>
                        <ul
                          className='dropdown-menu'
                          aria-labelledby='dropdownMenuButton1'
                        >
                          {user ? (
                            <>
                              {role == "admin" || role == "sub-admin" ? (
                                <>
                                  <li className='groupnav'>
                                    <Link
                                      activeClassName={
                                        router.pathname === "/groups1"
                                      }
                                      href='/groups1'
                                    >
                                      <a>{lang.groups}</a>
                                    </Link>
                                  </li>

                                  <li className='account'>
                                    <Link
                                      activeClassName={
                                        router.pathname === "/account/admin"
                                      }
                                      href='/account/admin'
                                    >
                                      <a>{lang.my_account}</a>
                                    </Link>
                                  </li>

                                  <li className='all-companies'>
                                    <Link
                                      activeClassName={
                                        router.pathname === "/all-companies"
                                      }
                                      href='/all-companies'
                                    >
                                      <a>{lang.all_companies}</a>
                                    </Link>
                                  </li>
                                  {(role == "admin" || role == "sub-admin") &&
                                  (Cookies.get("userid") == "61ab55f01184b71719412d9d" ||
                                    Cookies.get("userid") == "62c408fdea448a9d1afbc5a7" ||
                                    Cookies.get("userid") == "62c35ce051a43bc783a792fd") ? (
                                  <li className='legal-uploads'>
                                    <Link
                                      activeClassName={
                                        router.pathname === "/legal-uploads"
                                      }
                                      href='/legal-uploads'
                                    >
                                      <a>{lang.legal_upload}</a>
                                    </Link>
                                  </li>
                                    ):("")}
                                </>
                              ) : (
                                <li className='aging'>
                                  <Link
                                    activeClassName={
                                      router.pathname === "/aging"
                                    }
                                    href='/aging'
                                  >
                                    <a>{lang.aging}</a>
                                  </Link>
                                </li>
                              )}
                              {/* </>
                                                        : ''
                                                    }

                                                    {user ? */}
                              <li className='logout'>
                                <a
                                  onClick={() => {
                                    Cookies.remove("token");
                                    Cookies.remove("role");
                                    Cookies.remove("userid");
                                    Cookies.remove("name");
                                    Cookies.remove("company_id");
                                    Cookies.remove("email");
                                    Cookies.remove("NEXT_LOCALE");
                                    Cookies.remove("userData");
                                    Cookies.remove("viewed_company_id");
                                    Cookies.remove("exit_simulation");

                                    Cookies.remove("company_language");
                                    Cookies.remove("company_name");
                                    Cookies.remove("display_remove_legal");
                                    Cookies.remove("type");
                                    Cookies.remove("pdfname");
                                    Cookies.remove("pdfurl");
                                    Cookies.remove("report_order_id");
                                    Cookies.remove("_ga");
                                    Cookies.remove("extraUrl");
                                    Cookies.remove("flinks_row_id");
                                    Cookies.remove("plaid_row_id");
                                    Cookies.remove("reference_id");
                                    Cookies.remove("team_access_id");
                                    Cookies.remove("report_is_filled");
                                    Cookies.remove("pricing_id");
                                    Cookies.remove("subject");

                                    Cookies.remove("admin_token");
                                    Cookies.remove("admin_role");
                                    Cookies.remove("admin_userid");
                                    Cookies.remove("admin_name");
                                    Cookies.remove("admin_email");
                                    Cookies.remove("admin_companyID");
                                    Cookies.remove("admin_userData");

                                    router.push("/logout");
                                  }}
                                >
                                  {lang.logout}
                                </a>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                      <div className='humbergernav pull-right'>
                        <button onClick={() => toggleNav()}>
                          {myToggle ? (
                            <IoClose size={25} />
                          ) : (
                            <ImMenu size={25} />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Nav
              className={` main_nav navbar ${myToggle ? " open" : "notopen"}`}
            >
              <ul className={` ${myToggle ? "open" : "notopen"}`}>
                <li className={`hello_login`}>
                  <span className='hello'>
                    {lang.hello} {name}
                  </span>
                </li>
                {role == "admin" || role == "sub-admin" ? (
                  <div>
                    <li
                      className={`credit-report ${
                        router.pathname == "/credit-reports" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/credit-reports"}
                        href='/credit-reports'
                      >
                        <a className='nav-link'>{lang.credit_reports}</a>
                      </Link>
                    </li>

                    <li
                      className={`credit-report ${
                        router.pathname == "/credit-reports-completed"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname === "/credit-reports-completed"
                        }
                        href='/credit-reports-completed'
                      >
                        <a className='nav-link'>{lang.credit_reports1}</a>
                      </Link>
                    </li>

                    <li
                      className={`database-report ${
                        router.pathname.startsWith("/database-reports")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname.startsWith(
                          "/database-reports"
                        )}
                        href='/database-reports'
                      >
                        <a className='nav-link'>{lang.database_reports}</a>
                      </Link>
                    </li>

                    <li
                      className={`companies ${
                        router.pathname === "/companies" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/companies"}
                        href='/companies'
                      >
                        <a className='nav-link'>{lang.companies}</a>
                      </Link>
                    </li>
                    <li
                      className={`companies ${
                        router.pathname === "/credit-applications"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname === "/credit-applications"
                        }
                        href='/credit-applications'
                      >
                        <a className='nav-link'>{lang.application}</a>
                      </Link>
                    </li>
                    {(role == "admin" || role == "sub-admin") &&
                    (Cookies.get("userid") == "61ab55f01184b71719412d9d" ||
                      Cookies.get("userid") == "62c408fdea448a9d1afbc5a7" ||
                      Cookies.get("userid") == "62c35ce051a43bc783a792fd") ? (
                      <li
                        className={`companies ${
                          router.pathname === "/dispatch" ? "active" : ""
                        }`}
                      >
                        <Link
                          activeClassName={router.pathname === "/dispatch"}
                          href='/dispatch'
                        >
                          <a className='nav-link'>Dispatch</a>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* <li className={`all-companies ${router.pathname === "/all-companies" ? "active" : ""}`}>
                                        <Link activeClassName={router.pathname === "/all-companies"} href="/all-companies">
                                            <a className="nav-link">{lang.all_companies}</a>
                                        </Link>
                                    </li> */}
                    <div className='missing_tabs'>
                      <li
                        className={`groupnav ${
                          router.pathname === "/groups1" ? "active" : ""
                        }`}
                      >
                        <Link
                          activeClassName={router.pathname === "/groups1"}
                          href='/groups1'
                        >
                          <a className='nav-link'>{lang.groups}</a>
                        </Link>
                      </li>

                      <li
                        className={`account ${
                          router.pathname === "/account/admin" ? "active" : ""
                        }`}
                      >
                        <Link
                          activeClassName={router.pathname === "/account/admin"}
                          href='/account/admin'
                        >
                          <a className='nav-link'>{lang.my_account}</a>
                        </Link>
                      </li>

                      <li
                        className={`all-companies ${
                          router.pathname === "/all-companies" ? "active" : ""
                        }`}
                      >
                        <Link
                          activeClassName={router.pathname === "/all-companies"}
                          href='/all-companies'
                        >
                          <a className='nav-link'>{lang.all_companies}</a>
                        </Link>
                      </li>
                      
                      {(role == "admin" || role == "sub-admin") &&
                        (Cookies.get("userid") == "61ab55f01184b71719412d9d" ||
                        Cookies.get("userid") == "62c408fdea448a9d1afbc5a7" ||
                        Cookies.get("userid") == "62c35ce051a43bc783a792fd" )? (
                          <li
                            className={`legal-uploads ${
                              router.pathname === "/legal-uploads" ? "active" : ""
                            }`}
                          >
                            <Link
                              activeClassName={router.pathname === "/legal-uploads"}
                              href='/legal-uploads'
                            >
                              <a className='nav-link'>{lang.legal_upload}</a>
                            </Link>
                          </li>
                        ) : ("")}
                    </div>
                  </div>
                ) : (
                  <>
                  <div>
                    <li
                      className={`credit-report ${
                        router.pathname == "/credit-reports" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/credit-reports"}
                        href='/credit-reports'
                      >
                        <a className='nav-link'>{lang.my_credit_reports}</a>
                      </Link>
                    </li>

                    {/*<li className={`credit-report ${router.pathname == "/credit-reports-completed" ? "active" : ""}`}>
                                        <Link activeClassName={router.pathname === "/credit-reports-completed"} href="/credit-reports-completed">
                                            <a className="nav-link">{lang.credit_reports1}</a>
                                        </Link>
                                    </li>*/}

                    <li
                      className={`database-report ${
                        router.pathname == "/database-reports" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname === "/database-reports"
                        }
                        href='/database-reports'
                      >
                        <a className='nav-link'>{lang.my_database_reports}</a>
                      </Link>
                    </li>

                    <li
                      onClick={active_report}
                      className={`credit-report ${
                        router.asPath == "/credit-reports/report"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname === "/credit-reports/report"
                        }
                        href='/credit-reports/report'
                      >
                        <a className='nav-link'>{lang.order_report}</a>
                      </Link>
                    </li>

                    <li
                      className={`database-report ${
                        router.pathname == "/database-reports/search-new-report"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname ===
                          "/database-reports/search-new-report"
                        }
                        href='/database-reports/search-new-report'
                      >
                        <a className='nav-link'>
                          {lang.order_database_reports}
                        </a>
                      </Link>
                    </li>

                    <li
                      className={`legal-watchlist ${
                        router.pathname == "/legal-watchlist" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/legal-watchlist"}
                        href='/legal-watchlist'
                      >
                        <a className='nav-link'>{lang.legal_watchlist}</a>
                      </Link>
                    </li>

                    <li
                      className={`denunciation ${
                        router.pathname == "/denunciation" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/denunciation"}
                        href='/denunciation'
                      >
                        <a className='nav-link'>{lang.denunciation}</a>
                      </Link>
                    </li>

                    <li
                      className={`my-account ${
                        router.pathname == "/groups1" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/groups1"}
                        href='/groups1'
                      >
                        <a className='nav-link'>{lang.groups}</a>
                      </Link>
                    </li>
                    <li
                      className={`companies ${
                        router.pathname === "/credit-applications"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        activeClassName={
                          router.pathname === "/credit-applications"
                        }
                        href='/credit-applications'
                      >
                        <a className='nav-link'>{lang.application}</a>
                      </Link>
                    </li>
                    <li
                      className={`my-account ${
                        router.pathname == "/account" ? "active" : ""
                      }`}
                    >
                      <Link
                        activeClassName={router.pathname === "/account"}
                        href='/account'
                      >
                        <a className='nav-link'>{lang.my_account}</a>
                      </Link>
                    </li>
                    <li
                      className='health_monitoring_header'
                      onClick={onMigration}
                    >
                      <div className='health_monitoring'>
                        {lang.health_monitoring}
                      </div>
                    </li>

                    <div className='missing_tabs'>
                      <li
                        className={`aging ${
                          router.pathname === "/aging" ? "active" : ""
                        }`}
                      >
                        <Link
                          activeClassName={router.pathname === "/aging"}
                          href='/aging'
                        >
                          <a className='nav-link'>{lang.aging}</a>
                        </Link>
                      </li>
                    </div>
                  </div>
                  </>
                )}
                {user ? (
                  <span>
                    <li className='logout logoutheader'>
                      <a
                        className='nav-link'
                        onClick={() => {
                          Cookies.remove("token");
                          Cookies.remove("role");
                          Cookies.remove("userid");
                          Cookies.remove("name");
                          Cookies.remove("company_id");
                          Cookies.remove("email");
                          Cookies.remove("NEXT_LOCALE");
                          Cookies.remove("userData");
                          Cookies.remove("viewed_company_id");
                          Cookies.remove("exit_simulation");

                          Cookies.remove("company_language");
                          Cookies.remove("company_name");
                          Cookies.remove("display_remove_legal");
                          Cookies.remove("type");
                          Cookies.remove("pdfname");
                          Cookies.remove("pdfurl");
                          Cookies.remove("report_order_id");
                          Cookies.remove("_ga");
                          Cookies.remove("extraUrl");
                          Cookies.remove("flinks_row_id");
                          Cookies.remove("plaid_row_id");
                          Cookies.remove("reference_id");
                          Cookies.remove("team_access_id");
                          Cookies.remove("report_is_filled");
                          Cookies.remove("pricing_id");
                          Cookies.remove("subject");

                          Cookies.remove("admin_token");
                          Cookies.remove("admin_role");
                          Cookies.remove("admin_userid");
                          Cookies.remove("admin_name");
                          Cookies.remove("admin_email");
                          Cookies.remove("admin_companyID");
                          Cookies.remove("admin_userData");

                          router.push("/logout");
                        }}
                      >
                        {lang.logout}
                      </a>
                    </li>
                  </span>
                ) : (
                  ""
                )}
              </ul>
            </Nav>
          </div>
        </div>
      </>
    );
  } else
    return (
      <div className='header'>
        <div className='header_wrap'></div>
      </div>
    );
};

export default Header;
